<template>
  <div class="fadeIn">
    <el-card>
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        style="padding-top: 10px"
      >
        <el-form-item label="名称：">
          <el-input size="small" v-model="formInline.fileName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker
          size="small"
            v-model="formInline.date"      
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formInline.current=1,pageList()" size="small"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table height="650" :data="tableData" stripe style="width: 100%">
        <el-table-column prop="fileName" label="名称" width="180" align="center"></el-table-column>
        <el-table-column prop="fileState" label="文件状态" align="center" :formatter="formatter"> </el-table-column>
        <el-table-column prop="fileSize" label="文件大小" align="center">
          <template slot-scope="{row}">
            <span v-if="row.fileSize">{{ row.fileSize }}</span>
            <i class="el-icon-loading" v-else style="font-size: 16px;color: #0c7ee1;"></i>
          </template>
           </el-table-column>
        <el-table-column prop="createTime" label="生成时间" width="180" align="center"></el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="{row}">
            <el-button
            :disabled="!row.fileState"
            type="primary"
            plain
             size="mini" @click="getFile(row)">导出</el-button>
             <el-button
            type="danger"
            plain
             size="mini" @click="delFile(row)">删除</el-button>
          </template>
           </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" style="margin-top: 20px;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formInline.current"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="formInline.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formInline.total"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
let that
import { downLoad } from '../util/global'
export default {
  data() {
    return {
      timer:null,
      downLoad:downLoad,
      tableData: [],
      formInline: {
        fileName: '',
        date: '',
        size: 10,
        current: 1,
        total: 0,
        fileType:'',
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    delFile(file) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.$api.downLoad.delFile(file.historyFileId).then((res) => { 
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.pageList()
        }
      })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    getFile(file) {
      const { filePath, fileName } = file
      window.open(this.downLoad+filePath+fileName)
    },
    formatter(val) {
      return val.fileState? "完成" :"生成中"
    },
    pageList() {
      this.formInline.date==null? this.formInline.date='':this.formInline.date
      this.$api.downLoad.getList(this.formInline).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.formInline.total = res.data.total
          const flag = res.data.records.find(item => item.fileState == 0)
          clearTimeout(this.timer)
          this.timer=null
          if (flag) {
            this.timer=setTimeout(() => {
            that.pageList()
          },5000)
         }
        }
      })
    },
    handleCurrentChange(val) {
      this.formInline.current = val
      this.pageList()
    },
    handleSizeChange(val) {
      this.formInline.size = val
      this.pageList()
    },
    getDom() {
      const list = document.querySelectorAll(".el-cascader-node");
      list.forEach((item) => {
        item.onclick = function () {
          item.childNodes[0].click();
        };
      });
    },
    handleChange(value) {
      this.Ostatus='wait'
      if (value == "" || !value) return;
      this.Ostatus='success'
      const list = document.querySelectorAll(".el-cascader-node");
      list.forEach((item) => {
        item.onclick = function () {
          item.childNodes[0].click();
        };
      });
    },
  },
  created() {
    that=this
    this.pageList();
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer=null
  }
};
</script>
<style scoped>
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
/* @import url(); 引入css类 */
.el-card {
  height: 90vh;
}
::v-deep .el-card__body {
  padding: 10px !important;
}
.el-form-item{
  margin-right: 20px !important;
}
</style>
